import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <h2>{`Description`}</h2>
        <p>{`As part of Emagic‘s flagship product, Logic Audio, I developed a portable audio
engine. This audio engine (nicknamed PAD) powered Logic Audio from 1996 to 2000.
In addition, it was used in VMR and WaveBurner. The following key objectives have
been followed during the design:`}</p>
        <ul>
          <li parentName="ul">{`portability, code re-usability`}</li>
          <li parentName="ul">{`performance`}</li>
          <li parentName="ul">{`layered design`}</li>
          <li parentName="ul">{`low latency`}</li>
          <li parentName="ul">{`realtime DSP`}</li>
        </ul>
        <p>{`At the time of design, typical audio engines were usually specifically tailored
to the hardware/ operating system. As Emagic was a small company, an effort to
increase the amount of code shared across platforms was more than welcome. The
bottleneck of an audio engine was mainly the disk access. This was solved by
intensive caching with intelligent read-ahead schemes. Realtime DSP processing
was in its infancy, dominated by the process of mixing the output tracks. Memory
access was already an issue here, but due to limited DSP processing, the focus
was laid on implementing a scatter-gather scheme throughout the complete engine
to avoid superfluous copying of data. Logic Audio was a Win 16 application at
that time, programmed with Watcom‘s 32-bit extender. Windows 95, especially in
conjunction with 16-bit applications had unpredictable real-time behavior. PAD
reduced latency to about 200ms, which was an excellent value in 1996.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "622px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3b44568de35e6568063d64c4a13755ef/5e4ef/pad.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "62.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB1ZKyAZX/xAAWEAEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQEAAQUCalYxJj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMWGh/9oACAEBAAE/IU++ibpZbYstjMUp/9oADAMBAAIAAwAAABC7D//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAICAwEAAAAAAAAAAAAAAAERACExQVFh/9oACAEBAAE/ECPKDGyg9lvu3C3TPIooHI5N4Xyf/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "PAD Entity Relationship Diagram",
                "title": "PAD Entity Relationship Diagram",
                "src": "/static/3b44568de35e6568063d64c4a13755ef/5e4ef/pad.jpg",
                "srcSet": ["/static/3b44568de35e6568063d64c4a13755ef/f93b5/pad.jpg 300w", "/static/3b44568de35e6568063d64c4a13755ef/b4294/pad.jpg 600w", "/static/3b44568de35e6568063d64c4a13755ef/5e4ef/pad.jpg 622w"],
                "sizes": "(max-width: 622px) 100vw, 622px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2>{`Features`}</h2>
        <p>{`The PAD audio engine implements the following features:`}</p>
        <ul>
          <li parentName="ul">{`portability to various operating systems and programming models`}
            <ul parentName="li">
              <li parentName="ul">{`successful port to Win32, Mac OS 9, Be OS`}</li>
              <li parentName="ul">{`thunk layer to interface with Win16 applications`}</li>
              <li parentName="ul">{`more than 90% of hardware-independent code`}</li>
              <li parentName="ul">{`clear object-oriented design`}</li>
              <li parentName="ul">{`no use of assembly language`}</li>
            </ul>
          </li>
          <li parentName="ul">{`high performance`}
            <ul parentName="li">
              <li parentName="ul">{`up to 30 tracks of audio (44.1kHz, 16bit) from disk to Windows Multimedia
on a Pentium 100MHz with Windows 95 (in 1996)`}</li>
              <li parentName="ul">{`multi-threaded design`}</li>
              <li parentName="ul">{`use of asynchronous disk i/o`}</li>
              <li parentName="ul">{`broad hardware support`}</li>
              <li parentName="ul">{`adapted to Windows Multimedia, MacOS SoundManager, Audiowerk, EASI, ASIO`}</li>
            </ul>
          </li>
          <li parentName="ul">{`specific low-level features for digital audio workstations`}
            <ul parentName="li">
              <li parentName="ul">{`sample position interpolation by digital PLL`}</li>
              <li parentName="ul">{`zero-latency start to support several hardware devices running simultaneously`}</li>
              <li parentName="ul">{`level metering`}</li>
              <li parentName="ul">{`input monitoring`}</li>
            </ul>
          </li>
          <li parentName="ul">{`support for tracks`}
            <ul parentName="li">
              <li parentName="ul">{`every track can be routed and panned to two audio outputs`}</li>
              <li parentName="ul">{`mixing of tracks to outputs by host CPU or dedicated hardware`}</li>
            </ul>
          </li>
          <li parentName="ul">{`support for regions`}
            <ul parentName="li">
              <li parentName="ul">{`every region has a sample position and a length within the song`}</li>
              <li parentName="ul">{`associated to an audio file and an offset inside the file`}</li>
            </ul>
          </li>
          <li parentName="ul">{`support for realtime DSP`}
            <ul parentName="li">
              <li parentName="ul">{`plugins may be inserted into tracks, buses, outputs`}</li>
              <li parentName="ul">{`support for proprietary format and VST`}</li>
            </ul>
          </li>
          <li parentName="ul">{`disk cache`}
            <ul parentName="li">
              <li parentName="ul">{`read-ahead to reduce seeks`}</li>
              <li parentName="ul">{`support for WAV and AIFF audio files`}</li>
              <li parentName="ul">{`support for mono and stereo material`}</li>
            </ul>
          </li>
          <li parentName="ul">{`sophisticated debug features`}
            <ul parentName="li">
              <li parentName="ul">{`RIP hook to recover after crashes`}</li>
              <li parentName="ul">{`debug log feature to ease beta-test phase`}</li>
              <li parentName="ul">{`built-in performance tester to gather data during beta-test phase`}</li>
            </ul>
          </li>
        </ul>
        <h2>{`PAD-delta`}</h2>
        <p>{`Since the design of PAD several things have changed. With increasing power of
host CPUs, the focus shifted more and more to host-based DSP processing. This
also shifted the bottleneck of the audio engine to memory bandwidth. Another
thing we learnt was, that class hierarchies with lots of abstraction layers are
not as maintenance friendly as believed, and that restructuring such a hierarchy
is a major pain.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "622px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/021e412e37f05d5e8d0f0330f57cac83/5e4ef/pad-d.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "75%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHsNSCq/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREkH/2gAIAQEAAQUC6qVkZDLP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Air/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAABESFRMUH/2gAIAQEAAT8huVjMFB0u0T4L0Z//2gAMAwEAAgADAAAAEIjP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFhgf/aAAgBAQABPxCe3Byj2xx+q+5JLB7LkqerbdBw/N//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "PAD Entity Relationship Diagram",
                "title": "PAD Entity Relationship Diagram",
                "src": "/static/021e412e37f05d5e8d0f0330f57cac83/5e4ef/pad-d.jpg",
                "srcSet": ["/static/021e412e37f05d5e8d0f0330f57cac83/f93b5/pad-d.jpg 300w", "/static/021e412e37f05d5e8d0f0330f57cac83/b4294/pad-d.jpg 600w", "/static/021e412e37f05d5e8d0f0330f57cac83/5e4ef/pad-d.jpg 622w"],
                "sizes": "(max-width: 622px) 100vw, 622px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`PAD-delta was a redesign of PAD from 1999 to 2000, in an attempt to overcome
these issues by taking the following approach:`}</p>
        <ul>
          <li parentName="ul">{`single-precision floating point arithmetic`}
            <ul parentName="li">
              <li parentName="ul">{`better throughput than integer on Pentium and PowerPC CPUs`}</li>
              <li parentName="ul">{`more dynamic range`}</li>
              <li parentName="ul">{`better filter stability, easier coefficient calculation`}</li>
            </ul>
          </li>
          <li parentName="ul">{`reduced set of classes, less abstraction layers, flat hierarchy`}
            <ul parentName="li">
              <li parentName="ul">{`data source: disk cache or audio input`}</li>
              <li parentName="ul">{`data sink: playback device or disk cache`}</li>
              <li parentName="ul">{`DSP engine: the mechanics to perform DSP tasks`}</li>
            </ul>
          </li>
          <li parentName="ul">{`DSP engine executes linear list of tasks`}
            <ul parentName="li">
              <li parentName="ul">{`tasks specified by input buffer, output buffer, processing function`}</li>
              <li parentName="ul">{`concepts like tracks, buses, mixers all abstracted to tasks`}</li>
              <li parentName="ul">{`tasks can be added or removed dynamically`}</li>
              <li parentName="ul">{`order of tasks optimized in order to promote in-place processing`}</li>
              <li parentName="ul">{`small processing buffer size (typically 64 audio frames) to fit data into CPU caches`}</li>
            </ul>
          </li>
          <li parentName="ul">{`advanced disk cache`}
            <ul parentName="li">
              <li parentName="ul">{`consequent use of asynchronous i/o`}</li>
              <li parentName="ul">{`even more intelligent read-ahead to reduce disk seeks`}</li>
              <li parentName="ul">{`cache includes format conversion to floating point`}</li>
              <li parentName="ul">{`float data cached`}</li>
            </ul>
          </li>
        </ul>
        <p>{`While PAD-delta remained the best performing audio engine in the music industry,
it was replaced by an engine nicknamed MD in 2000 due to company internal
political reasons and licensing issues.`}</p>
      </Main>
      <Aside mdxType="Aside" />
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      